angular.module("app")
    .factory("travelPolicyService", ["$log", "$http", "$q", "currentOrganisation", function ($log, $http, $q, currentOrganisation) {
        var urls = {
            list: function (organisationReference) {
                return sprintf("/organisation/%s/travel-policies/", organisationReference);
            },
            create: function (organisationReference) {
                return sprintf("/organisation/%s/travel-policies/", organisationReference);
            },
            getTravelPolicy: function (organisationReference, travelPolicy) {
                return sprintf("/organisation/%s/travel-policies/%s", organisationReference, travelPolicy);
            },
            update: function (organisationReference, travelPolicy) {
                return sprintf("/organisation/%s/travel-policies/%s", organisationReference, travelPolicy);
            },
            remove: function (organisationReference, travelPolicy) {
                return sprintf("/organisation/%s/travel-policies/%s", organisationReference, travelPolicy);
            },
            getPolicyForPaymentMethod: function (paymentMethod) {
                return sprintf("/travel-policies/paymentMethod/%s", paymentMethod);
            },
            removePolicyFromPaymentMethod: function (travelPolicy, paymentMethod) {
                return sprintf("/travel-policies/%s/paymentMethod/%s", travelPolicy, paymentMethod);
            },
            validatePolicyName: function (policyName) {
                return sprintf("/travel-policies/validate/%s", policyName);
            },
            getDefaultPolicy: function (organisationReference) {
                return sprintf('/organisation/%s/default', organisationReference);
            }
        };

        var cachedTravelPolicies;

        var api = {
            list: function (inactive) {
                var params = {inactive: inactive};
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                        return $http.get(urls.list(organisationReference), {params: params})
                            .then(function (resp) {
                                return _.each(resp.data, normalisePolicy);
                            });
                    })
                    .then(api.updateCache);
            },
            create: function (travelPolicyInfo) {
                $log.debug("creating travel policy");
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                    return $http.post(urls.create(organisationReference), travelPolicyInfo)
                        .then(function (resp) {
                            return resp.data;
                        });
                });
            },
            get: function (policyReference) {
                $log.debug("get travel policy");
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                    return $http.get(urls.getTravelPolicy(organisationReference, policyReference))
                        .then(function (resp) {
                            return normalisePolicy(resp.data);
                        });
                });
            },
            updateTravelPolicy: function (travelPolicy) {
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                        return $http.put(urls.update(organisationReference, travelPolicy.travelPolicyReference), travelPolicy)
                            .then(function (resp) {
                                return normalisePolicy(resp.data);
                            });
                    })
                    .then(api.updateCache);
            },
            removeTravelPolicy: function (travelPolicyReference) {
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                    return $http.delete(urls.remove(organisationReference, travelPolicyReference))
                        .then(function (resp) {
                            return api.list(true);
                        });
                });
            },
            validatePolicyName: function (policyName) {
                return $http.get(urls.validatePolicyName(policyName))
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            updateCache: function (result) {
                if (result) {
                    if (_.isArray(result)) {
                        cachedTravelPolicies = _.keyBy(result, 'travelPolicyReference');
                    } else {
                        if (cachedTravelPolicies === undefined) {
                            cachedTravelPolicies = {};
                        }
                        cachedTravelPolicies[result.travelPolicyReference] = normalisePolicy(result);
                    }
                    return result;
                }
            },
            getDefaultPolicy: function () {
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                    return $http.get(urls.getDefaultPolicy(organisationReference))
                        .then(function (resp) {
                            if (resp.data) {
                                return normalisePolicy(resp.data);
                            }
                        });
                });
            },
            ensureCache: function () {
                if (cachedTravelPolicies === undefined) {
                    return api.list(true);
                } else {
                    var deferred = $q.defer();
                    deferred.resolve(cachedTravelPolicies);
                    return deferred.promise;
                }
            },
            dayOfWeek: function (day) {
                switch (day) {
                    case 'Any':
                        return 0;
                    case 'Mon':
                        return 1;
                    case 'Tue':
                        return 2;
                    case 'Wed':
                        return 3;
                    case 'Thu':
                        return 4;
                    case 'Fri':
                        return 5;
                    case 'Sat':
                        return 6;
                    case 'Sun':
                        return 7;

                }
            }
        };
        return api;

        function hasDefault(policies) {
            policies.forEach(function (policy) {
                if (policy.isDefault) {
                    return true;
                }
            });
            return false;
        }

        function normalisePolicy(travelPolicy) {
            var from = parseDateValue(travelPolicy.effectiveFrom);
            var to = parseDateValue(travelPolicy.effectiveTo);
            travelPolicy.effectiveFrom = from;
            travelPolicy.effectiveTo = to;
            travelPolicy.searchField = travelPolicy.name + " " + travelPolicy.description;
            if (to) {
                travelPolicy.isExpired = to < moment();
            } else {
                travelPolicy.isExpired = false;
            }
            _.each(travelPolicy.paymentMethods, function (method) {
                normalise(method);
            });
            return travelPolicy;
        }

        function normalise(paymentMethod) {
            if (paymentMethod) {
                paymentMethod.name = paymentMethod.card ? paymentMethod.card.nickname : paymentMethod.account.accountName;
                paymentMethod.displayType = paymentMethod.card ? "Card" : "Account";
                paymentMethod.searchField = paymentMethod.name + "  " + paymentMethod.displayType + " ";

                if (paymentMethod.card) {
                    paymentMethod.cardNumber = paymentMethod.card.cardNumber;
                }
                return paymentMethod;
            }
        }

        function parseDateValue(date) {
            if (!angular.isUndefined(date) && date !== "" && date !== null) {
                date = moment(date).toDate();
            } else {
                date = null;
            }
            return date;
        }
    }])
;